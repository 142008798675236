<template>
	<div class="content">
		<div class="setMenuColor" data-menu-color="light">
			<transition name="fade2">
				<div class="abdBanner">
					<div class="container">
						<h1 class="abdBanner--title">Ак Барс Дом</h1>
						<p class="abdBanner--desc">Сайт-каталог крупного застройщика<br class="show1100"> Республики Татарстан</p>
					</div>
					<div class="abdLines">
						<div class="abdLines--line2 animatedLine animatedLine--down animateOnLoad"></div>
						<div class="abdLines--line1 animatedLine animatedLine--up animateOnLoad"></div>
						<div class="abdLines--line3 animatedLine animatedLine--down animateOnLoad"></div>
						<div class="abdLines--line5 animatedLine animatedLine--down animateOnLoad"></div>
						<div class="abdLines--line4 animatedLine animatedLine--down animateOnLoad"></div>
						<div class="abdLines--line6 animatedLine animatedLine--up animateOnLoad"></div>
						<div class="abdLines--line7 animatedLine animatedLine--up animateOnLoad" :style="{backgroundImage: 'url(' + require(`@/assets/img/abd/lineBack.png`) + ')'}"></div>
						<div class="abdLines--line8 animatedLine animatedLine--down animateOnLoad" :style="{backgroundImage: 'url(' + require(`@/assets/img/abd/lineBack.png`) + ')'}"></div>
						<div class="abdLines--line9 animatedLine animatedLine--up animateOnLoad" :style="{backgroundImage: 'url(' + require(`@/assets/img/abd/lineBack2.png`) + ')'}"></div>
						<div class="abdLines--line10 animatedLine animatedLine--down animateOnLoad" :style="{backgroundImage: 'url(' + require(`@/assets/img/abd/lineBack3.png`) + ')'}"></div>
					</div>
				</div>
			</transition>
		</div>
		<div class="blockLeftMenu AbdBlockLeftMenu type4">
			<div class="blockLeftMenu__item abdBlock1 setMenuColor" data-menu-color="dark">
				<div class="abdBlock1--line1  animatedLine animatedLine--up"></div>
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>О проекте</p>
						</div>		
					</div>
					<div class="blockLeftMenu__right">
						<p class="lh28">
							Ак Барс Дом — девелоперская компания, входящая в тройку крупнейших застройщиков Республики Татарстан. Сайт обновленного бренда АБД демонстрирует жилые комплексы компании с возможностью удобного выбора квартиры и её планировки через каталог. Для автоматизации процессов бронирования и покупки жилья внедрены специализированные сервисы.
						</p>
						<div class="caseInfo__additionalData margin2">
							<a href="https://akbars-dom.ru/" class="abdPurple" target="_blank">akbars-dom.ru</a>
							<p>срок разработки: 2 месяца</p>
							<p>запуск: 12.10.2019 г.</p>
						</div>
						<div class="abdChat">
							<div class="abdChat__item left">
								<div class="abdChat__user">
									<div class="abdChat__user__img" :style="{backgroundImage: 'url(' + require(`@/assets/img/abd/user4.jpg`) + ')'}"></div>
									<div class="abdChat__user__content">
										<p class="abdChat__user--name">Максим Пранов</p>
										<p class="abdChat__user--desc">Начальник Управления цифровой трансформации Ак Барс Дом</p>
									</div>
								</div>
								<div class="abdChat__itemContainer purpleBack wow fadeInUp" data-wow-duration="0.6s">
									<span class="abdChat--icon icon-abd-icon"></span>
									<p class="abdChat--title">Задача</p>
									<div class="abdChat--text">
										Разработать сайт-каталог недвижимости с учетом ребрендинга Ак Барс Дом, транслируя имидж надежного и стабильного застройщика.
									</div>
									<span class="abdChat__item--arrow"></span>
								</div>
							</div>
							<div class="abdChat__item right">
								<div class="abdChat__user">
									<div class="abdChat__user__img" :style="{backgroundImage: 'url(' + require(`@/assets/img/abd/user3.png`) + ')'}"></div>
									<div class="abdChat__user__content">
										<p class="abdChat__user--name">Алексей Денисов</p>
										<p class="abdChat__user--desc">Проект-мендежер Артрокетс</p>
									</div>
								</div>
								<div class="abdChat__itemContainer pinkBack wow fadeInUp" data-wow-duration="0.6s">
									<span class="abdChat--icon icon-abd-icon"></span>
									<p class="abdChat--title">Решение</p>
									<div class="abdChat--text">
										При разработке использовали новый фирменный стиль. Каталог жилья максимально дружелюбен, разработан гибкий фильтр для подбора квартиры по пользовательским параметрам, продуман 3d-шоурум планировок и виртуальных экскурсий по квартирам.<br><br>
										Подчеркнули особенности проживания в каждом жилом комплексе застройщика.
									</div>
									<span class="abdChat__item--arrow"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock2 noBorder setMenuColor" data-menu-color="dark" style="background-color: #f7f4fc">
				<div class="abdBlock2--line1  animatedLine animatedLine--down" :style="{backgroundImage: 'url(' + require(`@/assets/img/abd/lineBack.png`) + ')'}"></div>
				<div class="abdBlock2--line2  animatedLine animatedLine--down"></div>
				<div class="abdBlock2--line3  animatedLine animatedLine--down"></div>
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Ребрендинг</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<p class="lh28 margin2">
							Новый бренд отражает желание компании Ак Барс Дом быть ближе к клиенту, проявить заботу о нем. Динамичный дизайн обновленного сайта транслирует главные ценности АБД: технологичность, постоянное развитие и фокус на потребностях и желаниях клиента. Каждый элемент и информационный блок выполняет главную задачу: выбор и покупка жилья — это  быстро, комфортно и максимально безопасно.
						</p>
						<div class="abdRebrand">
							<img :src="require(`@/assets/img/abd/oldLogo.png`)" class="abdRebrand--oldLogo" alt="">
							<div class="abdRebrand__video">
								<video :src="require(`@/assets/img/abd/logo.mp4`)" ref="videoLogo" muted="" playsinline="" :poster="require(`@/assets/img/abd/logo-preview.png`)"></video>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock3 whiteText noBorder setMenuColor" data-menu-color="light" style="background-color: #5E00C6">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Дизайн</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<p class="lh28 margin2" style="max-width: 670px; position: relative; z-index: 1;">
							Сделали акценты на типографике и качественных графических изображениях, использовали фирменные цвета и элементы. Страницы сайта функциональны, но при этом визуально просты и минималистичны.
						</p>
						<div class="abdBlock3__videoContainer">
							<div class="abdBlock3__video">
								<video :src="require(`@/assets/img/abd/design.mp4`)" ref="videoLogo" autoplay="" loop="loop" muted="" playsinline=""></video>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock4 noTopPadding noBottomPadding noBorder setMenuColor" data-menu-color="light" style="background-color: #5E00C6; margin-top: -1px">
				<div class="words" ref="words">
					<div class="words__up">
						<img :src="require(`@/assets/img/abd/words_up.png`)" alt="" :style="{'left': '-' + wordPos + 'px'}">
					</div>
					<div class="words__down">
						<img :src="require(`@/assets/img/abd/words_down.png`)" alt="" :style="{'right': '-' + wordPos + 'px'}">
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock5 whiteText noBorder setMenuColor" data-menu-color="light" style="background-color: #5E00C6">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content noFix">
							<p>Жилые комплексы</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<p class="lh28 margin2">
							При выборе места проживания для клиента нет мелочей. Люди выбирают свой будущий образ жизни,поэтому в информацию о жилом комплексе включены интересные детали об особенностях расположения будущего жилья. Подача контента проста и дружелюбна, задача текста не продать, а предоставить человеку информацию.
						</p>
						<div class="abdComplexList">
							<div class="abdTooltip abdComplexList--tooltip1 hide600 wow fadeInLeft" data-wow-duration="0.6s">
								<span class="abdTooltip--icon icon-abd-icon"></span>
								<p class="abdTooltip--title">Список всех ЖК</p>
								<div class="abdTooltip--text">
									Комплексы отображаются карточками с изображениями и кратким описанием.
								</div>
								<span class="abdTooltip--arrow"></span>
							</div>
							<div class="abdTooltip abdComplexList--tooltip2 hide600 wow fadeInLeft" data-wow-duration="0.6s">
								<span class="abdTooltip--icon icon-abd-icon"></span>
								<p class="abdTooltip--title">Иконки преимуществ</p>
								<div class="abdTooltip--text">
									Иконки легко узнаваемы, для расшифровки их значений добавлен сопровождающий текст.
								</div>
								<span class="abdTooltip--arrow"></span>
							</div>
							<div class="abdTooltip abdComplexList--tooltip3 hide600 wow fadeInLeft" data-wow-duration="0.6s">
								<span class="abdTooltip--icon icon-abd-icon"></span>
								<p class="abdTooltip--title">Качественные изображения ЖК</p>
								<div class="abdTooltip--text">
									Подобраны яркие и живые изображения жилых комплексов.
								</div>
								<span class="abdTooltip--arrow"></span>
							</div>

							<img class="abdComplexList--image hide1100" :src="require(`@/assets/img/abd/desktop2.png`)" alt="">
							<img class="abdComplexList--image show1100 hide600" :src="require(`@/assets/img/abd/desktop2-tablet.png`)" alt="">
							<img class="abdComplexList--image show600" :src="require(`@/assets/img/abd/desktop2-mobile.png`)" alt="">

							<div class="show600">
								<VueSlickCarousel class="abdTooltipSlider" v-bind="slickSettings">
									<div class="abdTooltip">
										<span class="abdTooltip--icon icon-abd-icon"></span>
										<p class="abdTooltip--title">Список всех ЖК</p>
										<div class="abdTooltip--text">
											Комплексы отображаются карточками с изображениями и кратким описанием.
										</div>
										<span class="abdTooltip--arrow"></span>
									</div>
									<div class="abdTooltip">
										<span class="abdTooltip--icon icon-abd-icon"></span>
										<p class="abdTooltip--title">Иконки преимуществ</p>
										<div class="abdTooltip--text">
											Иконки легко узнаваемы, для расшифровки их значений добавлен сопровождающий текст.
										</div>
										<span class="abdTooltip--arrow"></span>
									</div>
									<div class="abdTooltip">
										<span class="abdTooltip--icon icon-abd-icon"></span>
										<p class="abdTooltip--title">Качественные изображения ЖК</p>
										<div class="abdTooltip--text">
											Подобраны яркие и живые изображения жилых комплексов.
										</div>
										<span class="abdTooltip--arrow"></span>
									</div>
									<template #customPaging="page">
										<div class="dot2"></div>
									</template>
								</VueSlickCarousel>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock6 noBorder setMenuColor" data-menu-color="dark">
				<div class="abdBlock6--line1 animatedLine animatedLine--down"></div>
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Детальная страница ЖК</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<p class="lh28">
							При подборке новостройки важно знать точные сроки сдачи дома, стоимость, проектную документацию и инфраструктуру жилого комплекса: где находится детский сад, есть ли рядом парковка и зеленая зона. Всё это выводится на страницу комплекса, а для детального знакомства предусмотрены информационные блоки об архитектурном облике, благоустройстве и инфраструктурных преимуществах.
						</p>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock7 noBorder whiteText setMenuColor" data-menu-color="light">
				<div class="container" style="pointer-events: none;">
					<div class="abdPlan__header">
						<p class="abdPlan--title">Интерактивный генеральный план</p>
						<div class="abdPlan__text">
							Покупатель может выбрать дом на генеральном плане и подобрать в нём подходящую квартиру. На плане отображаются объекты инфраструктуры, расположенные на территории жилого комплекса.
						</div>
					</div>
				</div>
				<div class="abdPlan">
					<img class="abdPlan--image" :src="require(`@/assets/img/abd/plan.jpg`)" alt="">
					<img class="abdPlan--imageHover" :src="require(`@/assets/img/abd/plan-hover.png`)" alt="">
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock8 noBorder whiteText setMenuColor" data-menu-color="light" style="background: linear-gradient(78.66deg, #5F048E 1.47%, #FB2163 75.72%);">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content noFix">
							<p>Квартиры</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<p class="lh28 margin2">
							Подбор квартиры мечты должен быть легким и удобным — это одно из назначений сайта, поэтому каталогу недвижимости уделено много внимания.
						</p>
						<img class="margin2 abdBlock8--img1" :src="require(`@/assets/img/abd/desktop3.png`)" alt="">

						<p class="bold mb6">Фильтр</p>
						<p class="margin2">
							Выборка квартир осуществляется по ЖК, площади и этажности, количеству комнат, сроку сдачи дома и стоимости жилья. Фильтр учитывает взаимосвязь пунктов и выводит квартиры в строгом соответствии с заданными параметрами.
						</p>

						<div class="abdPhones hide600">
							<div class="abdPhones__container" :style="{'left': -phonesPos + 'px'}" ref="phones">
								<img :src="require(`@/assets/img/abd/mobile1.png`)" alt="">
								<img :src="require(`@/assets/img/abd/mobile2.png`)" alt="">
								<img :src="require(`@/assets/img/abd/mobile3.png`)" alt="">
								<img :src="require(`@/assets/img/abd/mobile1.png`)" alt="">
							</div>
						</div>
						<div class="show600 abdPhonesScroll">
							<PhotoScroll :data="phonesSlider" :clickable="false"></PhotoScroll>
						</div>

					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock9 noBorder setMenuColor" data-menu-color="dark">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Cтраница квартиры</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<p class="lh28 margin2">
							Клиент покупает глазами, поэтому выводится дизайн-проект квартиры с готовой стилевой концепцией. В некоторых случаях вместо макета с мебелью и интерьером выводится снимок кадастрового плана объекта.
						</p>
						<img class="margin2" :src="require(`@/assets/img/abd/desktop4.png`)" alt="">
						<p class="lh28 margin2">
							Покупатель может добавить квартиру в избранное, сохранить в PDF-формате или распечатать. Здесь же предусмотрено онлайн-бронирование и запись на консультацию со специалистом Ак Барс Дом.
						</p>
						<p class="bold mb6">Бронирование</p>
						<p class="margin2">
							Подтверждение брони происходит с помощью смс-кода. После смс подтверждения, контакты клиента попадают в CRM-систему застройщика, и квартира получает статус «бронь», статус обновляется и на сайте.
						</p>
						<img :src="require(`@/assets/img/abd/desktop5.png`)" alt="">
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock10 noBorder whiteText setMenuColor" data-menu-color="light" style="background-color: #5E00C6">
				<img class="abdBlock10--titleImage" :src="require(`@/assets/img/abd/title.png`)" alt="">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Планировка</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<p class="lh28 margin4">
							Для демонстрации планировок использовали как плоские чертежи в цвете и ч.б, объемные 3D модели, так и интерактивные форматы: обзор 360°, панорамный тур и VR экскурсия по квартире.
						</p>
						<div class="showroom margin4">
							<div class="showroom__video">
								<video :src="require(`@/assets/img/abd/showroom.mp4`)" muted="muted" autoplay="autoplay" playsinline="" loop="loop"></video>
							</div>
						</div>
						<p class="lh28">
							Интерактивный формат даёт эффект присутствия в наиболее популярных 2-3 комнатных квартирах с несколькими вариантами планировки. Виртуальная прогулка помогает принять обдуманное решение посетить квартиру в одном из ЖК и увидеть комнаты своими глазами. Для подбора подходящих вариантов жилья с похожей планировкой предусмотрен фильтр.
						</p>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock11 noTopPadding noBorder whiteText setMenuColor" data-menu-color="dark" style="background-color: #5E00C6;">
				<div class="abdBlock11--line1 animatedLine animatedLine--up" :style="{backgroundImage: 'url(' + require(`@/assets/img/abd/lineBack.png`) + ')'}"></div>
				<div class="abdBlock11--line2 animatedLine animatedLine--up"></div>
				<div class="abdBlock11--line3 animatedLine animatedLine--up"></div>
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p style=" z-index: 1; position: relative;">Рассчет стоимости ипотеки</p>
						</div>
					</div>
					<div class="blockLeftMenu__right" style=" z-index: 1; position: relative;">
						<p class="lh28 margin2">
							Калькулятор показывает, из каких условий складывается итоговая стоимость ипотеки. Двигая ползунки, клиент видит, как меняется стоимость кредита, подбирая приемлемые для себя параметры — это существенно экономит время до обращения в банк.
						</p>
						<img class="margin2" :src="require(`@/assets/img/abd/desktop7.png`)" alt="">
						<p class="lh28">
							Для получения ставок кредитования и предложений банков сайт интегрируется с сервисом «Цифровая ипотека».
						</p>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock12 noBorder setMenuColor" data-menu-color="dark">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Интеграция с Profitbase</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<div class="abdProfitbaseText margin2">
							<p class="lh28">
								Интеграция бизнес-логики сайта с цифровой экосистемой для застройщиков — Profitbase. Сервис включает в себя приложения для отдела маркетинга и продаж, а также личный кабинет клиента для покупки квартиры онлайн.
							</p>
							<img :src="require(`@/assets/img/abd/profitLogo.png`)" alt="">
						</div>
						<img class="margin2" :src="require(`@/assets/img/abd/profit.png`)" alt="">
						<p class="lh28">
							Каталог квартир на сайте синхронизируется в режиме реального времени с Profitbase с помощью обмена XML-таблицами. Все данные квартиры, что видит покупатель хранятся на стороне сервиса.
						</p>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock13 noBorder noTopPadding setMenuColor" data-menu-color="dark">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Адаптив</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<p class="lh28 margin4">
							Мы адаптировали сайт под все популярные разрешения. Его удобно просматривать на экране компьютера, планшета или смартфона. Сайт автоматически распознает разрешение экрана устройства, с которого на него зашли, и его тип, подключает или отключает различные элементы, изменяет размер шрифтов и ширину блоков.
						</p>
						<div class="abdAdaptive">
							<div class="abdAdaptive__desktop">
								<p class="abdAdaptive--title">Desktop 1440 px.</p>
								<img :src="require(`@/assets/img/abd/desktop8.png`)" alt="">
							</div>
							<div class="abdAdaptive__tablet">
								<p class="abdAdaptive--title">Tablet 768 px.</p>
								<img :src="require(`@/assets/img/abd/tablet.png`)" alt="">
							</div>
							<div class="abdAdaptive__mobile">
								<p class="abdAdaptive--title">Mobile 375 px.</p>
								<img :src="require(`@/assets/img/abd/mobile4.png`)" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item abdBlock14 setMenuColor" data-menu-color="dark">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Отзыв клиента</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<Reviews :data="reviewsData" :project="'abd'"></Reviews>
					</div>
				</div>
			</div>
			<!-- <div class="blockLeftMenu__item abdBlock14 setMenuColor" data-menu-color="dark">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Другие проекты</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<Projects 
							:data="projectsData"
							:quantity="3"
							:quantityTable="3"
							:quantityMobile="3"
							:showPagination="false"
							:smallElements="true"
						>
						</Projects>
					</div>
				</div>
			</div> -->
		</div>
		<section class="fullPageSectionContainer setMenuColor newForm" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback :type='2'></Feedback>
			</div>
		</section>
	</div>
</template>

<style lang="sass">
	@import '@/assets/sass/case/abd/Abd'
	@import '@/assets/sass/WithLeftMenu'
	@import '@/assets/sass/case/CaseInfo'
	@import '@/assets/sass/Slider'
</style>

<script>
	import 	projectsData from "@/assets/json/projects.json";
	import 	reviewsData from "@/assets/json/reviews.json";
	import 	Reviews from '@/components/app/Reviews'
	import 	Projects from '@/components/app/Projects'

	import 	VueSlickCarousel from 'vue-slick-carousel'
	import 	'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 	'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
	import 	PhotoScroll from '@/components/app/PhotoScroll'

	var leftMenuItems = null;
	function leftMenuItemsPosition(){
		leftMenuItems.forEach(item => {
			var parent = item.closest(".blockLeftMenu__left");
			var parentTop = parent.getBoundingClientRect().top + document.documentElement.scrollTop;
			var parentLeft = parent.offsetLeft;
			var parentHeight = parent.offsetHeight;
			var parentWidth = parent.offsetWidth;
			var itemTop = item.offsetTop;
			var scrollTop = window.pageYOffset;
			var windowWidth  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

			var top = 28;

			if(windowWidth < 1440){
				top = 70;
			}

			if(scrollTop + item.offsetHeight >= parentTop + parentHeight - top){
				item.style.top = "auto";
				item.style.bottom = "0px";
				item.style.position = "absolute";
				item.style.width = parentWidth + "px";
				item.style.left = "";
			} else if(scrollTop + top < parentTop){
				item.style.top = "";
				item.style.bottom = "";
				item.style.position = "";
				item.style.width = "";
				item.style.left = "";
			} else {
				item.style.top = top + "px";
				item.style.bottom = "auto";
				item.style.position = "fixed";
				item.style.width = parentWidth + "px";
				item.style.left = parentLeft + "px";
			}
		})
	}


	export default {
		metaInfo: {
			title: 'Создание сайта с каталогом недвижимости для компании АкБарсДом | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Подробная информация о выполненном проекте и об этапах работ по созданию сайта с каталогом недвижимости для компании АкБарсДом' },
				{ vmid: 'og:title', property: 'og:title', content: 'Создания сайта с каталогом недвижимости для компании АкБарсДом | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Подробная информация о выполненном проекте и об этапах работ по созданию сайта с каталогом недвижимости для компании АкБарсДом' },
			],
		},
		data: () => ({
			reviewsData,
			projectsData,
			wordPos: 0,
			phonesPos: 0,
			videoLogoPlayed: false,
			slickSettings: {
				dots: true,
				arrows: false,
				dotsClass: 'abdTooltipSlider__dots',
				infinite: true,
			},
			phonesSlider: {
				images: [
					{
						image: require(`@/assets/img/abd/mobile1.png`)
					},{
						image: require(`@/assets/img/abd/mobile2.png`)
					},{
						image: require(`@/assets/img/abd/mobile3.png`)
					},{
						image: require(`@/assets/img/abd/mobile1.png`)
					}
				]
			}
		}),
		name: 'abd',
		mounted(){
			var comp = this;
			leftMenuItems = document.querySelectorAll('.blockLeftMenu__left__content');
			comp.ticker();
			comp.phones();
			comp.initAnimatedLinesPos();
			comp.animateLines();
			window.onscroll = function() {
				comp.ticker();
				comp.phones();
				if(!comp.videoLogoPlayed){
					comp.playLogoVideoScroll();
				}
				comp.animateLines();
				leftMenuItemsPosition();
			}
			window.onresize = function() {
				comp.ticker();
				comp.phones();
				comp.initAnimatedLinesPos();
				comp.animateLines();
				leftMenuItemsPosition();
			}

			var elements = this.$el.getElementsByClassName("animateOnLoad");

			for (var index = 0; index < elements.length; index++) {
				var element = elements[index];
				element.classList.add('animate');
			}
			setTimeout(function(){
				var elements = comp.$el.getElementsByClassName("animateOnLoad");
				for (var index = 0; index < elements.length; index++) {
					var element = elements[index];
					element.classList.remove("animate");
					element.classList.remove("animateOnLoad");
				}
				comp.initAnimatedLinesPos();
			}, 700);
			
		},
		methods: {
			showBlock: function(button, blockName){
				var element = this.$refs[blockName];
				if(element.offsetParent === null){
					element.style.display = "block";
					button.target.text = 'Скрыть';
				} else {
					element.style.display = "none";
					button.target.text = 'Развернуть';
				}
			},
			ticker: function(){
				var scrollTop = document.documentElement.scrollTop;
				var windowHeight = window.innerHeight;
				var windowWidth = window.innerWidth;
				var windowBottomPos = scrollTop + windowHeight;
				var element = this.$refs["words"];
				var elementHeight = element.offsetHeight;
				var elementTopPos = element.getBoundingClientRect().top + document.documentElement.scrollTop;
				var elementBottomPos = elementTopPos + elementHeight;
				var imagesWidth = element.getElementsByTagName("img")[0].offsetWidth;

				if(windowBottomPos > elementTopPos && scrollTop < elementBottomPos){
					var percent = 1 - ((elementBottomPos - scrollTop) / (windowHeight + elementHeight));
					this.wordPos = (imagesWidth - windowWidth) * percent * 0.3;
				}
			},
			phones: function(){
				var scrollTop = document.documentElement.scrollTop;
				var windowHeight = window.innerHeight;
				var windowWidth = window.innerWidth;
				var windowBottomPos = scrollTop + windowHeight;
				var element = this.$refs["phones"];
				var imagesWidth = element.offsetWidth;

				var parent = element.closest(".abdPhones");
				var parentWidth = parent.offsetWidth;
				var parentLeftPos = parent.getBoundingClientRect().left;
				var parentHeight = parent.offsetHeight;
				var parentTopPos = parent.getBoundingClientRect().top + document.documentElement.scrollTop;
				var parentBottomPos = parentTopPos + parentHeight;

				if(windowBottomPos > parentTopPos && scrollTop < parentBottomPos){
					var percent = 1 - ((parentBottomPos - scrollTop) / (windowHeight + parentHeight));
					this.phonesPos = (imagesWidth - windowWidth + parentLeftPos) * percent;
				}
			},
			playLogoVideoScroll: function() {
				var video = this.$refs["videoLogo"];
				var videoTop = video.getBoundingClientRect().top + document.documentElement.scrollTop;

				if(videoTop < document.documentElement.scrollTop + window.innerHeight){
					video.play();
					this.videoLogoPlayed = true;
				}
			},
			initAnimatedLinesPos: function(){
				var elements = this.$el.getElementsByClassName("animatedLine");
				elements.forEach( function(element, index) {
					element.style.transform = "rotate(45deg)";
					var elementHeight = element.offsetHeight;
					var elementTopPos = element.getBoundingClientRect().top + document.documentElement.scrollTop;
					var elementBottomPos = elementTopPos + elementHeight;
					element.dataset.topPos = elementTopPos;
					element.dataset.bottomPos = elementBottomPos;
				});
			},
			animateLines: function(){
				var scrollTop = document.documentElement.scrollTop;
				var windowHeight = window.innerHeight;
				var windowWidth = window.innerWidth;
				var windowBottomPos = scrollTop + windowHeight;

				var elements = this.$el.getElementsByClassName("animatedLine");

				for (var index = 0; index < elements.length; index++) {
					var element = elements[index];
					if(element.classList.contains('animateOnLoad')){
						continue;
					}
					var direction = "down";
					if(element.classList.contains('animatedLine--up')){
						direction = "up";
					}

					var elementHeight = element.offsetHeight;
					var elementTopPos = element.dataset.topPos;
					var elementBottomPos = element.dataset.bottomPos;
					if(windowBottomPos > elementTopPos && scrollTop < elementBottomPos){
						var percent = 1 - ((elementBottomPos - scrollTop) / (windowHeight + elementHeight));

						var position = percent * 100 * (windowWidth / 1920) * 1.2;

						if(direction == "up"){
							element.style.transform = "translate("+ position +"px, "+ -position +"px) rotate(45deg)";
						} else{
							element.style.transform = "translate("+ -position +"px, "+ position +"px) rotate(45deg)";
						}
					}
				}
			}
		},
		components: {
			VueSlickCarousel,
			Reviews,
			Feedback: () => import('@/components/new/Forms/Feedback'),
			Projects,
			PhotoScroll
		}
	}
</script>
